<template>
	<div @dblclick="dblClickHandler" class="modal-overlay active">
		<div @click.stop="" class="modal">
			<span @click="toggleModal" class="close" title="Fechar campo">
				<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" 
						xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" 
						class=""
				>
						<path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path>
				</svg>
			</span>
						
			<div id="form">
				<h2 id="add_title">Adicionar link</h2>

				<form @submit.prevent="handleSubmit">
          <div class="input-group">
            <label class="sr-only" for="titulo">Título</label>
            <input required autocomplete="off" 
              type="text" id="add_titulo" name="titulo" placeholder="Título do link"
              v-model="modalData.titulo"
            >
          </div>

          <div class="input-group">
            <label class="sr-only" for="url">URL</label>
            <input required autocomplete="off" 
              type="url" id="add_url" name="url" placeholder="URL do link"
              v-model="modalData.url"
            >
          </div>

					<div class="input-group">
            <label class="sr-only">Setores</label>
            <div id="area_selecao_setores">
              <div
                v-for="(setor, index) of ['Administração', 'Comercial', 'Comunicação', 'Customer Success', 'Financeiro', 'Gestão de Pessoas', 'Jurídico', 'Tech']" :key="setor"
                :style="{ background: setoresColors[setor] }"
                  :class="{
                    selected: modalData.setores[setor]
                  }" 
                >
                  <input type="checkbox"
                    :id="`setores_${index}`"
                    v-model="modalData.setores[setor]"
                    style="display: none"
                  >
                  <label 
                    :class="{
                      'sr-only': true,
                    }" 
                    :for="`setores_${index}`"
                  >
                    {{setor}}
                  </label>
                </div>
            </div>
					</div>

					<div class="input-group actions">
						<button type="button" @click="toggleModal" class="button cancel clear_button">Cancelar</button>
						<button class="button new">Adicionar</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import EventService from '@/services/EventService'
import handleError from '@/mixins/handleError'

import setoresColors from '@/utils/colors'

export default {
  components: {
  },
  data() {
    return {
      modalData: {
        titulo: '', 
        url: '', 
        setores: {
          'Administração': false,
          'Comercial': false,
          'Comunicação': false,
          'Customer Success': false,
          'Financeiro': false,
          'Gestão de Pessoas': false,
          'Jurídico': false,
          'Tech': false,
        }
      },
      senhas_diferentes: false,
      setoresColors
    }
  },
  mixins: [handleError],
  methods: {
    toggleModal() {
      this.$emit('toggleAddLink', { target: 'addLink' })
    },
    dblClickHandler(e) {
      if(e.target.className === 'modal-overlay active')
        this.toggleModal()
    },
    handleSubmit() {
      const event = {
        ...this.modalData,
      }

      this.$store.dispatch('loading')

      EventService.createLink(event)
      .then(({ link_criado }) => {
        this.clearOnCreate()
        this.$emit('createdLink', { link_criado })
      })
      .catch(this.handleError)
    },
    clearOnCreate() {
      this.modalData = {
        titulo: '', 
        setores: {
          'Administração': false,
          'Comercial': false,
          'Comunicação': false,
          'Customer Success': false,
          'Financeiro': false,
          'Gestão de Pessoas': false,
          'Jurídico': false,
          'Tech': false,
        },
        url: '', 
      },

      this.$store.dispatch('notLoading')

      this.toggleModal()
      
      this.$Swal.fire({
          title: "Link adicionado!",
          icon: "success"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../Modals.scss';
@import "ModalLinks.scss";
</style>