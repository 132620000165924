<template>
  <div class="header_pagina">
    <h1>Links</h1>
		<my-button icone="add" texto="Adicionar link" @buttonClick="toggleModalLocal('addLink')" />
		<transition name="fade">
			<modal-add v-if="modalStates.addLink" @toggleAddLink="toggleModal" @createdLink="updateLink"/>
		</transition>
	</div>

  <section>
    <div>
			<p v-if="!loaded">Carregando...</p>
			<div v-else-if="setores.length == 0">
        <p>Nenhum link cadastrado!</p>
			</div>
		  <div v-else>
        <!-- Seletor do setor -->
        <div id="section_setores">
          <h2>Setores</h2>
          <div id="area_filtro_setores">
            <div
              v-for="setor in setoresLinks" :key="setor"
              :style="{ background: setoresColors[setor] }"
              :class="{ selected: filter.setor == setor }"
            >
              <button class="clear_button"
                @click="clickSetor"
              >
                <p class="nome_setor">{{setor}}</p>
              </button>
            </div>
          </div>
        </div>

        <div id='section_filtro' >
          <div id="header_filtro">
            <h2 id="titulo_section_filtro">Filtro de pesquisa:</h2>
            <input autocomplete="off" type="text" id="filtro_titulo" 
              placeholder="Título" v-model="filter.titulo"
            >
          </div>
          <!-- <div id="header_filtro">
            <h2 id='titulo_section_filtro'>Filtros de pesquisa</h2>
          </div>
          <div id="area_filtros">
            <div>
              <label for="filtro_titulo">Título</label>
              <input autocomplete="off" type="text"
                id="filtro_titulo" placeholder="Filtrar título"
                v-model="filter.titulo"
              >
            </div>
          </div> -->
        </div>


        <div id="acoes"
          v-if="linksSetores[filter.setor]?.length > 0"
        >
          <div id="regiao_acoes">
            <my-button icone="delete" texto="Deletar" 
              style="margin-right: 1rem;"
              @click="toggleDeleteLinks()"
            />

            <my-button icone="edit" texto="Editar" 
              v-if="linksSetores[filter.setor]?.length == 1"
              @buttonClick="toggleModalLocal('editLink')"
            />
            <transition name="fade">
              <modal-edit
                v-if="modalStates.editLink" 
                :link="linkEdit"
                @toggleEditLink="toggleModal" 
                @edittedLink="updateLink"
              />
            </transition>
          </div>
        </div>

        <!-- Tabela dos links -->
        <div v-for="setor in setores" 
          :key="setor.nome"
          v-show="filter.setor == setor.nome"
        >
          <tabela-links 
            :links="setor.links"
            :setor="setor.nome.replaceAll(' ', '')"
            :filter="filter"
            @checkmarkChange="selectLinks"
          ></tabela-links>
        </div>
			</div>
		</div>
  </section>
</template>

<script>
import EventService from "@/services/EventService";

import handleError from '@/mixins/handleError'
import toggleModals from '@/mixins/toggleModals'

import ModalAdd from '@/components/Modals/Links/Add.vue'
import ModalEdit from '@/components/Modals/Links/Edit'

import TabelaLinks from '@/components/Tables/Links/Links.vue'

import Button from '@/components/Buttons/Button.vue'

import setoresColors from '@/utils/colors'

export default {
	components: {
		'modal-add': ModalAdd,
		'modal-edit': ModalEdit,
		'my-button': Button,
		'tabela-links': TabelaLinks,
	},
  data() {
    return {
			loaded: false,
      setores: [],
      filter: {
        titulo: '',
        setor: ''
      },

			linkEdit: {},
      linksSetores: {
        "Administração": [],
        "Comercial": [],
        "Comunicação": [],
        "Customer Success": [],
        "Financeiro": [],
        "Gestão de Pessoas": [],
        "Jurídico": [],
        "Tech": [],
      },

			modalStates: {
				addLink: false,
				editLink: false,
			},
      setoresColors
    };
  },
  async mounted() {
    const response = await EventService.fetchLinks()
    this.loaded = true
    this.setores = response.ordenado
  },
  mixins: [handleError, toggleModals],
	methods: {
    filtroSetor(setor) {
      const setor_verificando = setor.nome.toUpperCase()

      const filtro_setor = this.filter.setor.toUpperCase()

      const flag_setor = setor_verificando.includes(filtro_setor)

      return flag_setor
    },
    clickSetor(event) {
      let setor_clicado = event.target.innerText

      if(setor_clicado == this.filter.setor)
        setor_clicado = ''

      if(this.linksSetores[setor_clicado]?.length == 1){
        const setor_selecionado = this.setores.filter(({nome}) => nome == setor_clicado)[0]

        const id_selecionado = this.linksSetores[setor_clicado][0]
        const link_selecionado_completo = setor_selecionado.links.filter(({_id}) => _id == id_selecionado)[0]

        this.linkEdit = link_selecionado_completo
      }
      else
        this.linkEdit = {}
        
      this.filter.setor = setor_clicado
    },

		selectLinks(checkmark) {
      if(checkmark.state) {
        this.linksSetores[this.filter.setor].push(checkmark.id)
      }
      else{
        this.linksSetores[this.filter.setor].splice(this.linksSetores[this.filter.setor].indexOf(checkmark.id), 1)
      }

      if(this.linksSetores[this.filter.setor].length !== 1) this.linkEdit = null
      else {
				const id_edit = this.linksSetores[this.filter.setor][0]
				let link_edit

        this.setores.forEach(setor => {
          const link_achado = setor.links.filter(link => link._id == id_edit)[0]
          link_edit = link_achado ? link_achado : link_edit
        })

        this.linkEdit = link_edit
      }
		},

    toggleDeleteLinks() {
			let title = `Deletar link`
			let text = `Tem certeza que quer deletar o link '${this.linkEdit?.titulo}'?`

			if(this.linksSetores[this.filter.setor]?.length > 1) {
				title += `s`
				text = `Tem certeza que quer deletar ${this.linksSetores[this.filter.setor]?.length} links?`
			}

      this.$Swal.fire({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (!result.isConfirmed) return
        try {
					const ids = [...this.linksSetores[this.filter.setor]]
					EventService.updateLink(true)
					.then(() => {
            this.clearLinks(ids)
						this.$store.dispatch('notLoading')
						this.$Swal.fire({
								title: "Operação de deleção concluída!",
								icon: "success"
						})
					})
					.catch(this.handleError)
          this.linksSetores[this.filter.setor] = []
        } catch(e) {
          this.handleError(e)
          this.linksSetores[this.filter.setor] = []
        }
      })
    },
    
    async updateLink(clear) {
      if(clear){
        this.linksSetores[this.filter.setor] = []
        this.linkEdit = null
      }
      this.loaded = false
      const response = await EventService.fetchLinks()
      this.loaded = true
      this.setores = response.ordenado
    },
  },
  computed: {
    setoresLinks() {
      return Array.from(new Set([...this.setores.map(({nome}) => nome)]))
    },
  }
}
</script>

<style lang="scss" scoped>
@import "/Links.scss";
</style>